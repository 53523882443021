exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-acne-consultation-js": () => import("./../../../src/pages/acne-consultation.js" /* webpackChunkName: "component---src-pages-acne-consultation-js" */),
  "component---src-pages-acne-products-js": () => import("./../../../src/pages/acne-products.js" /* webpackChunkName: "component---src-pages-acne-products-js" */),
  "component---src-pages-acne-treatments-js": () => import("./../../../src/pages/acne-treatments.js" /* webpackChunkName: "component---src-pages-acne-treatments-js" */),
  "component---src-pages-all-about-acne-js": () => import("./../../../src/pages/all-about-acne.js" /* webpackChunkName: "component---src-pages-all-about-acne-js" */),
  "component---src-pages-appointment-js": () => import("./../../../src/pages/appointment.js" /* webpackChunkName: "component---src-pages-appointment-js" */),
  "component---src-pages-body-skin-js": () => import("./../../../src/pages/body-skin.js" /* webpackChunkName: "component---src-pages-body-skin-js" */),
  "component---src-pages-book-an-appointment-js": () => import("./../../../src/pages/book-an-appointment.js" /* webpackChunkName: "component---src-pages-book-an-appointment-js" */),
  "component---src-pages-botox-js": () => import("./../../../src/pages/botox.js" /* webpackChunkName: "component---src-pages-botox-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-gift-ideas-js": () => import("./../../../src/pages/gift-ideas.js" /* webpackChunkName: "component---src-pages-gift-ideas-js" */),
  "component---src-pages-glycolic-peels-js": () => import("./../../../src/pages/glycolic-peels.js" /* webpackChunkName: "component---src-pages-glycolic-peels-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-injectable-fillers-js": () => import("./../../../src/pages/injectable-fillers.js" /* webpackChunkName: "component---src-pages-injectable-fillers-js" */),
  "component---src-pages-laser-hair-removal-js": () => import("./../../../src/pages/laser-hair-removal.js" /* webpackChunkName: "component---src-pages-laser-hair-removal-js" */),
  "component---src-pages-microdermabrasion-js": () => import("./../../../src/pages/microdermabrasion.js" /* webpackChunkName: "component---src-pages-microdermabrasion-js" */),
  "component---src-pages-oil-skin-js": () => import("./../../../src/pages/oil-skin.js" /* webpackChunkName: "component---src-pages-oil-skin-js" */),
  "component---src-pages-photo-rejuvenation-js": () => import("./../../../src/pages/photo-rejuvenation.js" /* webpackChunkName: "component---src-pages-photo-rejuvenation-js" */),
  "component---src-pages-photofacial-js": () => import("./../../../src/pages/photofacial.js" /* webpackChunkName: "component---src-pages-photofacial-js" */),
  "component---src-pages-pigmentation-js": () => import("./../../../src/pages/pigmentation.js" /* webpackChunkName: "component---src-pages-pigmentation-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-promotions-js": () => import("./../../../src/pages/promotions.js" /* webpackChunkName: "component---src-pages-promotions-js" */),
  "component---src-pages-restylane-js": () => import("./../../../src/pages/restylane.js" /* webpackChunkName: "component---src-pages-restylane-js" */),
  "component---src-pages-skin-care-consultations-js": () => import("./../../../src/pages/skin-care-consultations.js" /* webpackChunkName: "component---src-pages-skin-care-consultations-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-pages-what-is-acne-js": () => import("./../../../src/pages/what-is-acne.js" /* webpackChunkName: "component---src-pages-what-is-acne-js" */),
  "component---src-templates-city-template-js": () => import("./../../../src/templates/city-template.js" /* webpackChunkName: "component---src-templates-city-template-js" */)
}

